.productContainer{
    width: 100vw;
    height: 600px;
    padding: 2% 10% 5%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-direction: column;
    margin-bottom: 3%;
}
.required{
    border-bottom: 3px solid var(--formline);
}
.notrequired{
    border-bottom: none;
}
.productTitle{
    width: 100%;
    height: 10%;
}
.productTitle h1{
    color: var(--text);
    font-family: var(--h1Font);
    font-size: clamp(2rem,5vw, 3rem);
    font-weight: var(--h1Weight);
}
.productContent{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.productImage{
    width: 40%;
    height: 100%;
}
.productImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
}
.productContext{
    width: 50%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.productContext p{
    /* padding: 5%; */
    text-align: justify;

    font-family: var(--p1Font);
    font-weight: var(--p1Weight);
    font-size: clamp(var(--p1SizeMob),2vw,var(--p1Size));
    color: var(--text);
}
.productBtn{
    width: 230px;
    position: absolute;
    bottom: 0;
}
.btnormal{
    right: 5%;
}
.btreverse{
    left: 5%;
}
.reverse{
    flex-direction: row-reverse;
}
.normal{
    flex-direction: row;
}

.safetyProductListing{
    width: 100%;
    margin-top: 10%;
}
@media only screen and (max-width: 1070px){
    .safetyProductListing{
        width: 100%;
        margin-top: 25%;
    }
    .productContainer{
        height: auto;
    }
    .productContent{
        height: auto;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .productImage{
        width: 100%;
        height: auto;
        margin: 5% 0;
    }
    .productContext{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    .productContext p{
        /* padding: 5%; */
        text-align: justify;
        height: auto;
        width: 100%;
        margin: 5% 0;
    }
    .productBtn{
        width: 230px;
        position: static;
        bottom: 0;
        margin: 5% 0;
    }
}