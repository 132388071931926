.safetyWrapper{
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.serviceContainer{ 
    width: 100%;
    height: auto;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: space-between;
    
    flex-direction: column;
}
.serviceContent{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}
.serviceTitle{
    width: 100%;
    margin-bottom: 2%;
}
.serviceTitle h2, .topProductTitle h2{
    font-family: var(--h2Font);
    font-size: clamp(var(--h1SizeMob),5vw, var(--h1Size));
    font-weight: var(--h2Weight);
    color: var(--text);
}
.serviceContext{
    width: 50%;
}
.serviceContext p, .topic p, .productName p{
    font-family: var(--p1Font);
    font-size: var(--p1Size);
    font-weight: var(--p1Weight);
    color: var(--text);
}
.enqbtnsafety{
    width: max(40%, 230px);
    margin-top: 4rem;
}
.serviceimage{
    width: 50%;
    padding: 5%;
    margin-left: 5%;
    padding-right: 0;
    padding-top: 0;
}
.serviceimage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
}

.serviceTopics{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3%;
    margin-top: 5%;
    line-height: 0;
}
.topic{
    margin-left: 5%;
    cursor: pointer;
}
.activeService{
    border-bottom: .2rem solid var(--logoRed);
    
}
.topProductsContainer{
    width: 100%;
    margin-top: 2%;
}

.topProductTitle{
    width: 50%;
    height: 50px;
}
.productcontainer{
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8%;
    margin: 2%;
}
.product{
    width: 150px;
    height: 200px;
}

@media only screen and (max-width: 1070px){
    .serviceContent{
        flex-direction: column-reverse;
    }
    .serviceContext{
        width: 100%;
        margin-bottom: 20%;
    }
    .serviceimage{
        width: 100%;
        padding: 5%;
        margin-left: 0%;
        padding-right: auto;
        padding-top: auto;
        margin-bottom: 5%;
    }
    .serviceTopics{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3%;
        margin-top: 5%;
        line-height: 0;

        flex-direction: column;
        border-top: 3px solid var(--formline);;
        padding-top: 5%;
    }
    .topic{
        margin: 5%;
        margin-left: 0%;
        cursor: pointer;

    }
}